import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroTheOther from "../components/HeroTheOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const SsdlcSecurityTrainingPhasePageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  cstfsection,
  tissdsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} />
      <HeroTheOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-4">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0 0" />
          </div>
          <div className="columns">
            <div className="column is-6">
              <Descriptions descriptions={aboutsection.sidedescriptions} color={aboutsection.textcolor} margin="0 0" />
            </div>
            <div className="column is-6">
              <div style={{ maxWidth: 300, height: 10 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${cstfsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "8rem" }}>
            <Title title={cstfsection.title} color={cstfsection.textcolor} />
            <Title title={cstfsection.secondtitle} color={cstfsection.textcolor} />
            <SubTitle title={cstfsection.subheader} color={cstfsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={cstfsection.descriptions} color={cstfsection.textcolor} margin="1rem 0" />
          </div>
        </div>
      </section>
      <section className="section" style={{ margin: "5rem 0 2rem" }}>
        <div className="container">
          <div className="columns" >
            <div className="column is-8">
              <Title title={tissdsection.title} />
              <SubTitle title={tissdsection.subheader1} margin="1.5rem 0" />
              <Descriptions descriptions={tissdsection.sidedescriptions} margin="1rem 0" />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={tissdsection.image} />
              </div>
            </div>
          </div>
          <Descriptions descriptions={tissdsection.descriptions1} margin="1rem 0" />
          <SubTitle title={tissdsection.subheader2} margin="1.5rem 0 " />
          <Descriptions descriptions={tissdsection.descriptions2} margin="1rem 0" />
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

SsdlcSecurityTrainingPhasePageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  cstfsection: PropTypes.object,
  tissdsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SsdlcSecurityTrainingPhasePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SsdlcSecurityTrainingPhasePageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        cstfsection={frontmatter.cstfsection}
        tissdsection={frontmatter.tissdsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SsdlcSecurityTrainingPhasePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SsdlcSecurityTrainingPhasePage

export const pageQuery = graphql`
  query SsdlcSecurityTrainingPhasePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ssdlc-security-training-phase" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cstfsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        tissdsection {
          title
          subheader1
          descriptions1
          sidedescriptions
          subheader2
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
